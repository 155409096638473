import React, { Component } from "react";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faInfoCircle
} from "@fortawesome/free-solid-svg-icons";

import LayoutItouch from "../../components/Layout";
import Seo from "../../components/Seo";
import fccid from "../../images/fccid.png";

const RegistrationSchema = Yup.object().shape({
    firstName: Yup.string()
        .min(2, 'Too Short!')
        .max(25, 'Too Long!')
        .required('Required'),
    lastName: Yup.string()
        .min(2, 'Too Short!')
        .max(25, 'Too Long!')
        .required('Required'),
    email: Yup.string()
        .lowercase()
        .email('Invalid email')
        .required('Required'),
    street: Yup.string()
        .min(2, 'Too Short!')
        .max(25, 'Too Long!')
        .required('Required'),
    city: Yup.string()
        .min(2, 'Too Short!')
        .max(25, 'Too Long!')
        .required('Required'),
    state: Yup.string()
        .uppercase()
        .min(2, 'Please enter two character state code.')
        .max(2, 'Please enter two character state code.')
        .required('Required'),
    postalCode: Yup.string()
        .min(2, 'Too Short!')
        .max(10, 'Too Long!')
        .matches('^[0-9]{5}(-[0-9]{4})?$', 'Please enter 5 digit or Zip+4 Code.')
        .required('Required'),
    phone: Yup.string()
        .matches('^\\+((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\\W*\\d){0,13}\\d$', 'Invalid Example: +1-212-555-1212')
        .required('Required'),
    deviceMAC: Yup.string()
        .matches('^[0-9A-Fa-f]{2}([:-]?)(?:[0-9A-Fa-f]{2}\\1){4}[0-9A-Fa-f]{2}$', 'Invalid MAC Address.  Please Re-enter.'),
    fccid: Yup.string()
        .min(10, 'Too Short!')
        .max(18, 'Too Long!')
        .uppercase()
        .matches('^2A|^XJ', 'Invalid FCC ID.  Please Check and try again.')
        .required('Required'),
});

class WarrantyRegistration extends Component {

    render() {
        return (
            <LayoutItouch>
                <Seo
                    keywords={[`warranty`, `watch`]}
                    title="X-Five Warranty Registration"
                />

                <section>
                    <div className="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
                        <div className="relative max-w-xl mx-auto">
                            <svg
                                className="absolute left-full transform translate-x-1/2"
                                width="404"
                                height="404"
                                fill="none"
                                viewBox="0 0 404 404"
                                aria-hidden="true"
                            >
                                <defs>
                                    <pattern
                                        id="85737c0e-0916-41d7-917f-596dc7edfa27"
                                        x="0"
                                        y="0"
                                        width="20"
                                        height="20"
                                        patternUnits="userSpaceOnUse"
                                    >
                                        <rect
                                            x="0"
                                            y="0"
                                            width="4"
                                            height="4"
                                            className="text-gray-200"
                                            fill="currentColor"
                                        />
                                    </pattern>
                                </defs>
                                <rect
                                    width="404"
                                    height="404"
                                    fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
                                />
                            </svg>
                            <svg
                                className="absolute right-full bottom-0 transform -translate-x-1/2"
                                width="404"
                                height="404"
                                fill="none"
                                viewBox="0 0 404 404"
                                aria-hidden="true"
                            >
                                <defs>
                                    <pattern
                                        id="85737c0e-0916-41d7-917f-596dc7edfa27"
                                        x="0"
                                        y="0"
                                        width="20"
                                        height="20"
                                        patternUnits="userSpaceOnUse"
                                    >
                                        <rect
                                            x="0"
                                            y="0"
                                            width="4"
                                            height="4"
                                            className="text-gray-200"
                                            fill="currentColor"
                                        />
                                    </pattern>
                                </defs>
                                <rect
                                    width="404"
                                    height="404"
                                    fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
                                />
                            </svg>
                            <div className="pb-4">
                                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl text-center">
                                    Warranty Registration
                </h2>
                                <p className="mt-4 text-lg leading-6 text-gray-500 text-center">
                                    Register here for your X-Five Smartwatch Warranty.
                </p>                                <p className="mt-4 text-lg leading-6 text-gray-500 text-center italic">
                                    If you've already registered and would like to file a claim, please complete the claim form on <Link className="underline text-blue-400" to='/warrantyClaim'>this page</Link>.
                </p>
                                <p className="mt-4 text-lg leading-6 text-red-500 float-left">
                                    * Required
                </p>
                            </div>

                            <div className="mt-12">

                                <Formik
                                    initialValues={
                                        {
                                            brand: 'xfive',
                                            recordType: 'warranty-registration',
                                            firstName: '',
                                            lastName: '',
                                            email: '',
                                            street: '',
                                            city: '',
                                            state: '',
                                            postalCode: '',
                                            phone: '',
                                            gifted: '',
                                            watchType: '',
                                            other: '',
                                            deviceMAC: '',
                                            proofOfPurchase: '',
                                            fccid: '',
                                            emailOptIn: true
                                        }
                                    }
                                    validationSchema={RegistrationSchema}
                                    onSubmit={(values, { setSubmitting, resetForm }) => {
                                        setTimeout(() => {
                                            fetch(
                                                "https://api.itouchwearables.com/registration",
                                                {
                                                    method: "POST",
                                                    mode: "cors",
                                                    cache: "no-cache",
                                                    body: JSON.stringify(values),
                                                    headers: {
                                                        Accept: "application/json; charset=utf-8",
                                                        "Content-Type": "application/json; charset=UTF-8",
                                                    },
                                                }
                                            )
                                                .then((response) => response.json())
                                                .then((data) => {
                                                    if (data.status === "success") {
                                                        alert("Your Warranty Registration has sucessfully completed.");
                                                        resetForm({ values: '' });
                                                    } else if (data.status === "fail") {
                                                        alert(
                                                            "Sorry, your Registration attempt has failed; Please try again later."
                                                        );
                                                    }
                                                });
                                            setSubmitting(false);
                                        }, 400);
                                    }}
                                >
                                    {({ errors, touched }) => (
                                        <Form
                                            className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                                            <div>
                                                <label
                                                    htmlFor="firstName"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    First name *
                    </label>
                                                <div className="mt-1">
                                                    <Field
                                                        hidden
                                                        type="text"
                                                        name="brand"
                                                        value="xfive"
                                                    />
                                                    <Field
                                                        hidden
                                                        type="text"
                                                        name="recordType"
                                                        value="warranty-registration"
                                                    />
                                                    <Field
                                                        type="text"
                                                        name="firstName"
                                                        autoComplete="given-name"
                                                        className="py-3 text-black px-4 block w-full shadow-sm focus:border-black border-gray-700 border-4 border-opacity-50 rounded-md"
                                                    />
                                                    {errors.firstName && touched.firstName ? (
                                                        <div className="text-red-500">{errors.firstName}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div>
                                                <label
                                                    htmlFor="lastName"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Last name *
                    </label>
                                                <div className="mt-1">
                                                    <Field
                                                        type="text"
                                                        name="lastName"
                                                        autoComplete="family-name"
                                                        className="py-3 text-black px-4 block w-full shadow-sm focus:border-black border-gray-700 border-4 border-opacity-50 rounded-md"
                                                    />
                                                    {errors.lastName && touched.lastName ? (
                                                        <div className="text-red-500">{errors.lastName}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="sm:col-span-2">
                                                <label
                                                    htmlFor="email"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Email *
                    </label>
                                                <div className="mt-1">
                                                    <Field
                                                        name="email"
                                                        type="email"
                                                        autoComplete="email"
                                                        className="py-3 text-black px-4 block w-full shadow-sm focus:border-black border-gray-700 border-4 border-opacity-50 rounded-md"
                                                    />
                                                    {errors.email && touched.email ? <div className="text-red-500">{errors.email}</div> : null}
                                                </div>
                                            </div>

                                            <div className="sm:col-span-2">
                                                <label
                                                    htmlFor="street"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Street Address
                    </label>
                                                <div className="mt-1">
                                                    <Field
                                                        name="street"
                                                        type="text"
                                                        autoComplete="street-address"
                                                        className="py-3 text-black px-4 block w-full shadow-sm focus:border-black border-gray-700 border-4 border-opacity-50 rounded-md"
                                                    />
                                                    {errors.street && touched.street ? <div className="text-red-500">{errors.street}</div> : null}
                                                </div>
                                            </div>

                                            <div className="sm:col-span-1">
                                                <label
                                                    htmlFor="city"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    City
                    </label>
                                                <div className="mt-1">
                                                    <Field
                                                        name="city"
                                                        type="text"
                                                        autoComplete="address-level2"
                                                        className="py-3 text-black px-4 block w-full shadow-sm focus:border-black border-gray-700 border-4 border-opacity-50 rounded-md"
                                                    />
                                                    {errors.city && touched.city ? <div className="text-red-500">{errors.city}</div> : null}
                                                </div>
                                            </div>

                                            <div className="sm:col-span-1">
                                                <label
                                                    htmlFor="state"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    State
                    </label>
                                                <div className="mt-1">
                                                    <Field
                                                        name="state"
                                                        type="text"
                                                        autoComplete="address-level"
                                                        className="py-3 text-black px-4 block w-full shadow-sm focus:border-black border-gray-700 border-4 border-opacity-50 rounded-md"
                                                    />
                                                    {errors.state && touched.state ? <div className="text-red-500">{errors.state}</div> : null}
                                                </div>
                                            </div>

                                            <div className="sm:col-span-1">
                                                <label
                                                    htmlFor="postalCode"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Postal Code
                    </label>
                                                <div className="mt-1">
                                                    <Field
                                                        name="postalCode"
                                                        type="text"
                                                        autoComplete="postal-code"
                                                        className="py-3 text-black px-4 block w-full shadow-sm focus:border-black border-gray-700 border-4 border-opacity-50 rounded-md"
                                                    />
                                                    {errors.postalCode && touched.postalCode ? <div className="text-red-500">{errors.postalCode}</div> : null}
                                                </div>
                                            </div>

                                            <hr className="sm:col-span-2" />

                                            <div className="sm:col-span-1">
                                                <label
                                                    htmlFor="phone"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Phone number
                    </label>
                                                <div className="mt-1">
                                                    <Field
                                                        name="phone"
                                                        type="text"
                                                        autoComplete="phone"
                                                        className="py-3 text-black px-4 block w-full shadow-sm focus:border-black border-gray-700 border-4 border-opacity-50 rounded-md"
                                                    />
                                                    {errors.phone && touched.phone ? <div className="text-red-500">{errors.phone}</div> : null}
                                                </div>
                                            </div>

                                            <hr className="sm:col-span-2" />

                                            <fieldset className="sm:col-span-2">
                                                <legend className="block text-sm font-medium text-gray-700">
                                                    Did you receive your X-Five Wearables Smartwatch or
                                                    Fitness Tracker as a gift? *
                    </legend>
                                                <div className="mt-4 grid grid-cols-1 gap-y-4">
                                                    <div className="flex items-center">
                                                        <Field
                                                            name="gifted"
                                                            value="true"
                                                            type="radio"
                                                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-700 border-4 border-opacity-50"
                                                        />
                                                        <label htmlFor="gifted" className="ml-3">
                                                            <span className="block text-sm text-gray-700">
                                                                Gifted to me
                          </span>
                                                        </label>
                                                    </div>
                                                    <div className="flex">
                                                        <Field
                                                            name="gifted"
                                                            value="false"
                                                            type="radio"
                                                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-700 border-4 border-opacity-50"
                                                        />
                                                        <label htmlFor="myself" className="ml-3">
                                                            <span className="block text-sm text-gray-700">
                                                                Bought for myself
                          </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </fieldset>

                                            <hr className="sm:col-span-2" />

                                            <fieldset className="sm:col-span-2">
                                                <legend className="block text-sm font-medium text-gray-700">
                                                    Watch Type *
                    </legend>
                                                <div className="mt-4 grid grid-cols-1 gap-y-4">
                                                    <div className="flex items-center">
                                                        <Field
                                                            name="watchType"
                                                            value="itouchSport"
                                                            type="radio"
                                                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-700 border-4 border-opacity-50"
                                                        />
                                                        <label htmlFor="itouchSport" className="ml-3">
                                                            <span className="block text-sm text-gray-700">
                                                                X-Five R
                          </span>
                                                        </label>
                                                    </div>
                                                    <div className="flex">
                                                        <Field
                                                            name="watchType"
                                                            value="itouchAirSE"
                                                            type="radio"
                                                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-700 border-4 border-opacity-50"
                                                        />
                                                        <label htmlFor="itouchAirSE" className="ml-3">
                                                            <span className="block text-sm text-gray-700">
                                                                X-Five S
                          </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </fieldset>

                                            <div className="sm:col-span-2">
                                                <div className="mt-0 sm:col-span-2">
                                                    <Field
                                                        name="other"
                                                        type="text"
                                                        className="py-3 text-black px-4 block w-full shadow-sm focus:border-black border-gray-700 border-4 border-opacity-50 rounded-md"
                                                    />
                                                </div>
                                            </div>

                                            <hr className="sm:col-span-2" />

                                            <div className="sm:col-span-2">
                                                <label
                                                    htmlFor="deviceMAC"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Please enter Device MAC Address <span className='has-tooltip'><FontAwesomeIcon icon={faInfoCircle} /><span className='tooltip rounded shadow-lg p-3 bg-gray-500 border-2 border-black text-white -mt-8'>The MAC ADDRESS is a combination of letters and numbers used to uniquely identify your watch. To verify this through your watch, long press the watch screen for 3-5 seconds until the MAC ADDRESS appears.</span></span>
                                                </label>
                                                <div className="mt-1">
                                                    <Field
                                                        name="deviceMAC"
                                                        type="text"
                                                        className="py-3 text-black px-4 block w-full shadow-sm focus:border-black border-gray-700 border-4 border-opacity-50 rounded-md"
                                                    />
                                                    {errors.deviceMAC && touched.deviceMAC ? (
                                                        <div className="text-red-500">{errors.deviceMAC}</div>
                                                    ) : null}
                                                </div>
                                            </div>


                                            <hr className="sm:col-span-2" />

                                            <div className="sm:col-span-2">
                                                <div className="sm:col-span-2">
                                                    <label
                                                        htmlFor="fccid"
                                                        className="block text-sm font-medium text-gray-700"
                                                    >
                                                        Please enter your X-Five Wearables FCC ID *
                      </label>
                                                    <div className="mt-1">
                                                        <Field
                                                            name="fccid"
                                                            type="text"
                                                            className="py-3 text-black px-4 block w-full shadow-sm focus:border-black border-gray-700 border-4 border-opacity-50 rounded-md"
                                                        />
                                                        {errors.fccid && touched.fccid ? (
                                                            <div className="text-red-500">{errors.fccid}</div>
                                                        ) : null}
                                                    </div>
                                                </div>

                                                <div>
                                                    <div className="pt-5">
                                                        Your FCC ID is Located on the back of your Smartwatch:
                      </div>
                                                    <div>
                                                        <img
                                                            className="px-5 py-5"
                                                            style={{ maxWidth: "210px" }}
                                                            src={fccid}
                                                            alt="FCC ID"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <hr className="sm:col-span-2" />

                                            <fieldset className="sm:col-span-2">
                                                <div className="mt-4 grid grid-cols-1 gap-y-4">
                                                    <div className="flex items-center">
                                                        <Field
                                                            type="checkbox"
                                                            name="emailOptIn"
                                                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-700 border-4 border-opacity-50"
                                                        />
                                                        <label htmlFor="opt-in" className="ml-3">
                                                            <span className="block text-sm text-gray-700">
                                                                Please keep me updated on exclusive member promotions and
                                                                events from X-Five Wearables
                              </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </fieldset>

                                            <div className="sm:col-span-2">
                                                <button
                                                    type="submit"
                                                    className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-black hover:bg-gray-700"
                                                >
                                                    Submit
                                    </button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </section>
            </LayoutItouch>
        );
    }
}

export default WarrantyRegistration;
